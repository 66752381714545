/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { Formik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import Spacer from '@app/components/atoms/Spacer';
import { useUser } from '@app/helpers/hooks';
import TextField from '../../../atoms/TextField';
import Button from '../../../atoms/Button';
import Link from '../../../atoms/Link';
import Checkbox from '../../../atoms/Checkbox';
import { SignInContainer, ButtonContainer, StyledForm } from './style';
import MutationsStatus from '../../../molecules/MutationsStatus';
import { useTranslation } from '../../../../config/i18n';
import { 
  setAuthCookie, 
  goToDashboard, 
  // redirectToRoute 
} from '../../../../helpers';
import Fragemets from '../../../../apollo/fragments';

const SIGNIN_MUTATION = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      access_token
      user {
        ...UserInformation
      }
    }
  }
  ${Fragemets.userInfo}
`;

const SignInForm = () => {
  const router = useRouter();
  const { setUser } =  useUser();

  const { query = {} } = router;

  const client = useApolloClient();
  const [
    SignInMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(SIGNIN_MUTATION);

  const { t } = useTranslation('common');

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [mobileSize, setMobileSize] = useState(false)

  const SignIn = input => {
    SignInMutation({
      variables: { input },
    });
  };

  useEffect(() => {
    if (window.innerWidth < 450) {
      setMobileSize(true)
    }
  }, [])

  if (mutationData) {
    const { access_token, user } = mutationData.login;
    setAuthCookie(access_token, rememberMe);
    setUser(user)

    let next = query.next || null;
    if (next) {
      next = decodeURIComponent(next);
    }

    client.cache.reset().then(() => {
      if (next) {
        router.push(next);
        // const nextRoute = JSON.parse(next);
        // redirectToRoute({}, nextRoute.name, { params: nextRoute.params, query: nextRoute.params });
      } else {
        goToDashboard({}, user);
      }
    });
  }

  return (
    <>
      <MutationsStatus loading={mutationLoading} error={mutationError} success={mutationData} />
      {/* <Button onClick={doSomeThing}></Button> */}
      <Grid container spacing={1}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.username) {
              errors.username = t('validation.req');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
              errors.username = t('validation.email');
            }

            if (!values.password) {
              errors.password = t('validation.req');
            }
            return errors;
          }}
          onSubmit={(values) => {
            SignIn(values);
            // let next = null;
            // if (query && query.next) {
            //   next = query.next;
            //     const nextRoute = JSON.parse(next);
            //     console.log('next', next);
            //     console.log('nextRoute',nextRoute);
            //     redirectToRoute({}, nextRoute.name, nextRoute.params);
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            /* and other goodies */
          }) => (
            <StyledForm>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="username"
                  label={t('email')}
                  value={values.username}
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.username && errors.username}
                  helperText={touched.username && errors.username}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="password"
                  label={t('password')}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        id="toggle-password-visibility"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={()=> (setShowPassword(!showPassword))}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(event.target.checked);
                  }}
                  inputProps={{
                    'aria-label': t('remember'),
                  }}
                  label={t('remember')}
                />
              </Grid>
              <ButtonContainer item xs={12}>
                <Button
                  id="submit"
                  type="submit"
                  disabled={mutationLoading}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  loading={mutationLoading}
                  fullWidth
                >
                  {t('login')}
                </Button>
              </ButtonContainer>
              <SignInContainer container item xs={12} spacing={1}>
                <Grid item lg={12} xs={12}>
                  {t('no_account')}{' '}
                  {mobileSize && <Spacer width="100%" height='10px' />}
                  <Link href="register" passHref>
                    {t('register-new')}
                  </Link>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Link href="pass_forgot" passHref>
                    {t('forgot_pass')}
                  </Link>
                </Grid>
              </SignInContainer>
            </StyledForm>
          )}
        </Formik>
      </Grid>
    </>
  );
};

SignInForm.propTypes = {};

export default SignInForm;
